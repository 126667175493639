<template>
    <form class="filters" @submit.prevent="submit">
        <div class="title">
            {{ $t('Filters') }}
        </div>
        <div class="btn-container">
            <ButtonComponent class="w-100 me-2" size="flexible" :caption="$t('Apply')" @click="applyFilters" />
            <ButtonComponent size="flexible" :caption="$t('Clear')" theme="bordered" @click="clearFilter"/>
        </div>
        <div class="inputs-container" v-if="!preloader">
            <div class="select-item">
                <MultiselectComponent
                    :value="distributorValue"
                    @change="multiselectChangeDistributors"
                    :options="distributorList"
                    :show-label="true"
                    :placeholder="$t('Click on the box to select distributors')"
                    :label="$t('Distributors')"
                    name="distributors"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                />
            </div>

            <div class="select-item">
                <MultiselectComponent
                    :value="movieValue"
                    @change="multiselectChangeMovies"
                    :options="movieList"
                    :show-label="true"
                    :placeholder="$t('Click on the box to select movies')"
                    :label="$t('Movies')"
                    name="movies"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                />
            </div>

            <div class="select-item">
                <MultiselectComponent
                    :value="networksValue"
                    @change="multiselectChangeNetworks"
                    :options="networksList"
                    :show-label="true"
                    :placeholder="$t('Click on the box to select networks')"
                    :label="$t('Networks')"
                    name="networks"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                />
            </div>

            <MultiselectComponent
                :value="cinemasValue"
                @change="multiselectChangeCinemas"
                :options="allCinemasList"
                :show-label="true"
                :placeholder="$t('Click on the box to select cinemas')"
                :label="$t('Cinemas')"
                name="cinemas"
                class="mt-4"
                :multiselectClass="'multiselect--mini'"
            />

            <div class="select-item">
                <MultiselectComponent
                    :value="hallsValue"
                    @change="multiselectChangeHalls"
                    :disabled="isHallsDisabled"
                    :options="hallsList"
                    :show-label="true"
                    :placeholder="$t('Click on the box to select halls')"
                    :label="$t('Halls')"
                    name="halls"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                />
            </div>
            <div class="select-item">

                <MultiselectComponent
                    :value="sessionsValue"
                    @change="multiselectChangeSessions"
                    :disabled="isSessionsDisabled"
                    :options="sessionsList"
                    :show-label="true"
                    :placeholder="$t('Click on the box to select sessions')"
                    :label="$t('Sessions')"
                    name="sessions"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                />
            </div>
        </div>
        <Preloader v-else />
    </form>
</template>

<script>
import SelectComponent from "../forms/SelectComponent";
import MultiselectComponent from "@/components/ui/forms/MultiselectComponent";
import ButtonComponent from "@/components/ui/ButtonComponent";
import Preloader from "@/components/ui/Preloader"

export default {
    name: "FiltersAnalytic",
    components: {
        SelectComponent,
        MultiselectComponent,
        ButtonComponent,
        Preloader
    },
    data() {
        return {
            allCinemasList: [],
            distributorList: [],
            movieList: [],
            hallsList: [],
            sessionsList: [],
            networksList: [],
            distributorValue: '',
            movieValue: '',
            hallsValue: '',
            cinemasValue: '',
            networksValue: '',
            sessionsValue: '',

            isCinemasLoaded: false,
            isDistributorsLoaded: false,
            isMoviesLoaded: false,
            isNetworksLoaded: false,
            isHallsLoaded: false,
            isHallsDisabled: true,
            isSessionsLoaded: false,
            isSessionsDisabled: true,
            isFiltersLoaded: false,
            preloader: true,
        };
    },
    computed: {
        getAppliedFilters() {
            return this.$store.getters.getAppliedFilters
        }
    },
    methods: {
        applyFilters() {
            let cinemas = this.cinemasValue ? this.cinemasValue : []
            let halls = this.hallsValue ? this.hallsValue : []
            let networks = this.networksValue ? this.networksValue : []
            let distributors = this.distributorValue ? this.distributorValue : []
            let movies = this.movieValue ? this.movieValue : []
            let sessions = this.sessionsValue ? this.sessionsValue : []
            let group = ['cinema_id'];
            this.$store.dispatch('addSidebarFilters', {cinemas, halls, distributors, movies, networks, sessions, group})
        },
        async getDistributorList(params = null) {
            if (this.distributorValue.length) {
                return false
            }
            this.isDistributorsLoaded = false
            this.distributorList = []
            let resp = await this.api.distributor.getDists(params);
            resp.data.forEach(item => {
                this.distributorList.push({
                    id: item.id,
                    label: item.name
                });
            });
            this.isDistributorsLoaded = true
        },
        async getMoviesList(params = null) {
            if (this.movieValue.length) {
                return false
            }
            this.isMoviesLoaded = false
            this.movieList = []
            let resp = await this.api.cinema.getFilmsList(params);
            if (resp){
                this.preloader = false
            }
            resp.data.forEach(item => {
                this.movieList.push({
                    id: item.id,
                    label: item.name
                });
            });
            this.isMoviesLoaded = true
        },
        async getNetworksList(params = null) {
            if (this.networksValue.length) {
                return false
            }
            this.isNetworksLoaded = false
            this.networksList = []
            let resp = await this.api.network.getNetworks(params);
            resp.data.forEach(item => {
                this.networksList.push({
                    id: item.id,
                    label: item.name
                });
            });
            this.isNetworksLoaded = true
        },
        async getCinemaHalls(params = null) {
            this.isHallsLoaded = false
            this.hallsList = []
            params ? this.isHallsDisabled = false : this.isHallsDisabled = true
            let resp = await this.ApiService.cinema.getCinemasHalls(params);
            resp.data.forEach(item => {
                this.hallsList.push({
                    id: item.id,
                    label: item.name
                });
            });

            this.isHallsLoaded = true
        },
        async getSessions(params = null) {
            this.isSessionsLoaded = false
            this.sessionsList = []
            params ? this.isSessionsDisabled = false : this.isSessionsDisabled = true
            let resp = await this.ApiService.session.getSessions(params)
            if (resp?.length) {
                resp.map(item => {
                    let date = this.moment(item).format('YYYY-MM-DD');
                    let time = this.helper.getTime(item)
                    this.sessionsList.push({
                        id: date + ' ' + time,
                        label: date + ' ' + time
                    });
                });
            }

            this.isSessionsLoaded = true
        },
        async getCinemasList(params = null) {
            if (this.cinemasValue.length) {
                return false
            }
            this.isCinemasLoaded = false
            this.allCinemasList = []
            let resp = await this.api.cinema.getCinemas(params);
            resp.data.forEach(item => {
                this.allCinemasList.push({
                    id: item.id,
                    label: item.name
                });
            });
            this.isCinemasLoaded = true
        },
        clearFilter() {
            this.distributorValue = [];
            this.movieValue = [];
            this.hallsValue = [];
            this.sessionsValue = [];
            this.networksValue = []
            this.cinemasValue = []

            this.isCinemasLoaded = false;
            this.isDistributorsLoaded = false
            this.$nextTick(()=> {
                this.isCinemasLoaded = true;
                this.isDistributorsLoaded = true
            })
        },
        multiselectChangeCinemas(v) {
            this.cinemasValue = []

            v.map(elem => {
                this.cinemasValue.push(elem.id)
            })

            if (this.cinemasValue.length > 0) {
                if (this.isHallsLoaded == true) {
                    this.getCinemaHalls({cinemas_id: this.cinemasValue, public: 1 })
                }
            }
            else {
                this.hallsList = [];
                this.isHallsDisabled = true
            }

            this.getDistributorList({no_pagination: true, cinemas_id: this.cinemasValue, public: 1 })
            this.getMoviesList({no_pagination: true, cinemas_id: this.cinemasValue, public: 1 })
            this.getNetworksList({no_pagination: true, cinemas_id: this.cinemasValue, public: 1 })
        },
        multiselectChangeNetworks(v) {
            this.networksValue = []

            v.map(elem => {
                this.networksValue.push(elem.id)
            })

            this.getDistributorList({no_pagination: true, networks_id: this.networksValue, public: 1 })
            this.getMoviesList({no_pagination: true, networks_id: this.networksValue, public: 1 })
            this.getCinemasList({no_pagination: true, networks_id: this.networksValue, public: 1 })
        },
        multiselectChangeDistributors(v) {
            this.distributorValue = []

            v.map(elem => {
                this.distributorValue.push(elem.id)
            })
            this.getMoviesList({no_pagination: true, distributors_id: this.distributorValue, public: 1 })
            this.getCinemasList({no_pagination: true, distributors_id: this.distributorValue, public: 1 })
            this.getNetworksList({no_pagination: true, distributors_id: this.distributorValue, public: 1 })
        },
        multiselectChangeHalls(v) {
            this.hallsValue = []

            v.map(elem => {
                this.hallsValue.push(elem.id)
            })

            if (this.hallsValue.length > 0) {
                this.getSessions({ date_from: this.getAppliedFilters?.date_from, date_to: this.getAppliedFilters?.date_to, cinema_halls: this.hallsValue, public: 1 })
            }
            else {
                this.sessionsList = [];
                this.isSessionsDisabled = true
            }
        },
        multiselectChangeSessions(v) {
            this.sessionsValue = []

            v.map(elem => {
                this.sessionsValue.push(elem.id)
            })
        },
        multiselectChangeMovies(v) {
            this.movieValue = []

            v.map(elem => {
                this.movieValue.push(elem.id)
            })

            this.getDistributorList({no_pagination: true, movies_id: this.movieValue, public: 1 })
            this.getCinemasList({no_pagination: true, movies_id: this.movieValue, public: 1 })
            this.getNetworksList({no_pagination: true, movies_id: this.movieValue, public: 1 })
        },
        submit() {}
    },
    async mounted() {
        this.getDistributorList({ no_pagination: true, public: 1 });
        this.getMoviesList({ no_pagination: true, public: 1 });
        this.getNetworksList({ no_pagination: true, public: 1 });
        this.getCinemasList({ no_pagination: true, public: 1 });
        this.getCinemaHalls({public: 1});
        this.getSessions({public: 1})
        this.isSessionsLoaded = true
        this.isFiltersLoaded = true
    }
};
</script>

<style scoped lang="scss">
.filters {
    .title {
        font-size: 18px;
        font-weight: 600;
        color: #141213;
        padding-bottom: 24px;
        border-bottom: 1px solid #cad9f3;
    }

    .btn-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 25px;
        @media (max-width: 992px) {
            display: none;
        }
        .btn:last-child{
            @media (max-width: 1200px) and (min-width: 992px){
                padding: 13px 13px;
            }
        }
    }

    .cinema-list {
        margin-top: 25px;

        .label {
            font-size: 16px;
            color: #646e78;
        }

        .items-container {
            display: flex;
            flex-wrap: wrap;
            border-radius: 2px;
            border: solid 1px #cad9f3;
            align-items: center;
            min-height: 45px;
            padding: 10px;

            .item {
                margin-right: 13px;
                display: flex;
                align-items: baseline;

                .delete-btn {
                    height: 15px;
                    width: 15px;
                    border: none;
                    border-radius: 50%;
                    color: white;
                    text-align: center;
                    margin-left: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: black;
                    font-size: 13px;

                    &:hover {
                        background-color: $color_darkblue;
                    }
                }
            }
        }
    }

    .select-item {
        margin-top: 25px;
    }
}
</style>
