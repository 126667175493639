<template>
    <div class="cinema-analytics">
        <div class="container">
            <Breads :links="breadLinks"/>
            <PageHeader :title="$t('Analytics')" />
        </div>
        <div class="page-content cinema-analytics__content container">

            <div class="cinema-analytics__mobileButton">
                 <button
                    class="navbar-toggler"
                    type="button"
                    @click="toggleMenu"
                >
                    <span class="material-icons">menu</span>
                </button>
            </div>

            <div class="cinema-analytics__aside col-lg-4" :class="menuVisible ? 'cinema-analytics__aside--visible' : ''">

                <FiltersAnalytic ref="filtersAnalytic" />
            </div>
            <div class="cinema-analytics__main col-12 col-lg-8">
                <div class="mobile-buttons">
                    <ButtonComponent class="me-2" size="flexible" :caption="$t('Apply')" @click="updateSortData" />
                    <ButtonComponent size="flexible" :caption="$t('Clear')" theme="bordered" @click="clearFilter"/>
                </div>

                <GraphFilterAnalytic @updateSortData="updateSortData" />
                <div class="cinema-analytics__chart">
                    <ChartsContainer />
                </div>
            </div>
        </div>
<!--        <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.5.1/chart.min.js" integrity="sha512-Wt1bJGtlnMtGP0dqNFH1xlkLBNpEodaiQ8ZN5JLA5wpc1sUlk/O5uuOMNgvzddzkpvZ9GLyYNa8w2s7rqiTk5Q==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>-->
    </div>
</template>

<script>
import Breads from "@/components/Breads";
import FiltersAnalytic from "@/components/ui/filters/FiltersAnalytic";
import GraphFilterAnalytic from "@/components/ui/filters/GraphFilterAnalytic";
import PageHeader from "@/components/PageHeader";
import ChartsContainer from "@/components/ui/charts/ChartsContainer";
import ButtonComponent from "../../components/ui/ButtonComponent";

export default {
    name: "CinemaAnalytics",
    components:{
        Breads,
        FiltersAnalytic,
        GraphFilterAnalytic,
        PageHeader,
        ChartsContainer,
        ButtonComponent
    },
    data() {
        return {
            menuVisible: false,
            breadLinks: [
                {
                    label: this.$t('Analytics'),
                    to: { name: 'CinemaAnalytics' }
                }
            ],
        }
    },

    methods: {
        toggleMenu() {
            this.menuVisible = !this.menuVisible
        },
        clearFilter(){
            this.$refs.filtersAnalytic.clearFilter()
        },
        screenTest(e) {
            if (e.matches) {
                this.menuVisible = false
            }
        },
        updateSortData(){
            this.$refs.filtersAnalytic.applyFilters()
        }
    },

    mounted() {
        window.matchMedia(`(min-width: 992px)`).addEventListener('change', this.screenTest)
    },
}
</script>

<style scoped lang="scss">

    .cinema-analytics {

        .mobile-buttons{
            display: none;
            @media (max-width: 992px){
                display: flex;
                margin: 20px;
                margin-left: 57px;
            }
            @media (max-width: 420px) {
                margin: 10px 5px;
                ::v-deep{
                    .btn{
                        width: 120px;
                    }
                }
            }
        }

        &__content {
            display: flex;
            justify-content: space-between;
            background-color: $color_light;
            position: relative;
        }

        &__aside, &__main {
            min-height: 833px;
            border-radius: 2px;
            background-color: #fff;
            padding: 24px 15px;
            max-width: 32%;
        }

        &__aside {
            transition: .4s;

            @media screen and (max-width: $lg) {
                left: -500px;
                position: absolute;
                //opacity: 0;
            }

            &--visible {
                position: absolute;
                z-index: 12;
                width: 372px;
                max-width: 100%;
                left: 0;
                top: 20px;
                //opacity: 1;
                box-shadow: 0 14px 50px #bfbcbc;
            }
        }

        &__main {
            max-width: 65%;

            @media screen and (max-width: $lg) {
                max-width: 100%;
            }
        }

        &__chart {
            padding-top: 20px;
        }

        &__mobileButton {
            display: none;
            position: absolute;
            top: -17px;
            left: -2px;
            background: #0c0032;
            border-radius: 4px;
            padding: 3px 0;
            z-index: 15;

            span {
                font-size: 19px;
                color: white;
            }

            @media screen and (max-width: $lg) {
                display: flex;
            }
        }
    }
    ::v-deep{
        .page-header{
            margin-top: 13px;
        }
        //.graph-filter{
        //    @media (max-width: 992px) {
        //        margin-top: 60px;
        //    }
        //}
        //.btn-container{
        //    @media (max-width: 992px) {
        //        position: absolute;
        //        left: 500px;
        //    }
        //}
    }
</style>
