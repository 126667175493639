<template>
    <div class="graph-filter">
        <div class="inputs-container">
            <DatePickerComponent
                class="date-picker"
                name="date"
                v-model="filtersData.date"
                :clearable="true"
                :range="true"
                :valueType="'format'"
                :showLabel='true'
                :placeholder="$t('Date')"
            />
            <SelectComponent
                ref="getcities"
                @input="citiesChange"
                :clearable="true"
                :options="cities"
                :show-label="true"
                :placeholder="$t('City')"
                @clearSearch="clearSearch"
                v-model="filtersData.city"
                name="region_id"
            />
        </div>
        <div class="btn-container">
            <div class="block">
                <div class="label">{{ $t('Display') }}</div>
                <div class="date-container">
                    <button @click="sortBy.time='day'" :class="{active : sortBy.time==='day'}" class="btn">{{ $t('Day') }}</button>
                    <button @click="sortBy.time='week'" :class="{active : sortBy.time==='week'}" class="btn">{{ $t('Week') }}</button>
                    <button @click="sortBy.time='month'" :class="{active : sortBy.time==='month'}" class="btn">{{ $t('Month') }}</button>
                </div>
            </div>
            <div class="block">
                <div class="sort-by">
                    <button @click="sortByType('quantity')" :class="{active : sortBy.sort==='quantity'}" class="btn">{{ $t('Count') }}</button>
                    <button @click="sortByType('money')" :class="{active : sortBy.sort==='money'}" class="btn">{{ $t('Amount') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePickerComponent from "../forms/DatePickerComponent";
import SelectComponent from "../forms/SelectComponent";

export default {
    name: "GraphFilterAnalytic",
    components: {
        DatePickerComponent,
        SelectComponent
    },
    data () {
        return {
            filtersData: {
                date: [new Date().toISOString().slice(0,10), new Date().toISOString().slice(0,10)],
            },
            cities: [],
            sortBy: {
                time: 'day',
                sort: 'quantity'
            },
            oldFilters: ''
        }
    },
    computed: {
        getAppliedFilters() {
            return this.$store.getters.getAppliedFilters
        },
        sortBySort() {
            return this.sortBy.sort
        }
    },
    watch: {
        getAppliedFilters(sidebarFilters) {
            if (JSON.stringify(this.oldFilters) !== JSON.stringify(sidebarFilters)) {

                let allFilters = {
                    region_id: this.filtersData.city,
                    format: this.sortBy.time,
                    date_from: this.filtersData.date[0],
                    date_to: this.filtersData.date[1],
                    ...sidebarFilters
                }
                this.oldFilters = allFilters
                this.$store.commit('addAllFilters', allFilters)
            }
        },
        sortBySort(){
            this.$emit('updateSortData')
        }
    },
    methods:{
        async getCities(query = null){
            query.public = 1
            let cities = await this.ApiService.city.getCities(query)
            cities.map(elem => {
                elem.label = elem.full_name
                return elem
            })
            this.cities = cities;
        },

        citiesChange(v) {
            this.filtersData.city = v
        },

        clearSearch(v) {
            this.filtersData.city = null;
        },

        sortByType(t) {
            this.sortBy.sort = t
            this.$store.commit('setSortByType', t)
        }
    },
    created() {
        this.getCities({ cities: 1 })
    }
}
</script>

<style scoped lang="scss">
.graph-filter{
    border-bottom: 1px solid #cad9f3;
    padding-bottom: 25px;
    .inputs-container{
        display: flex;

        @media screen and (max-width: $sm) {
            flex-direction: column;

            label {
                width: 50px;
            }
        }
    }
    .date-picker, .select{
        width: 50%;
    }
    .date-picker{
        width: 100%;
        margin-right: 72px;

        @media screen and (max-width: $sm) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .select-element  {
        width: 100%;
        margin-right: 20px;
    }

    .btn-container{
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        @media screen and (max-width: $md) {
            flex-direction: column;
        }

        .block{
            display: flex;
            align-items: center;

            &:last-child {
                @media screen and (max-width: $md) {
                    margin-top: 20px;
                }
            }

            &:first-child {
                @media screen and (max-width: $sm) {
                    flex-wrap: wrap;
                }

                .date-container {
                    @media screen and (max-width: $sm) {
                        margin-top: 10px;
                    }
                }
            }

        }
        .btn{
            width: 90px;
            height: 31px;
            border-radius: 2px;
            border: solid 1px #cad9f3;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            letter-spacing: 0.35px;
            color: #152847;
            &:focus{
                box-shadow: none;
            }

        }
        .active{
            font-size: 14px;
            letter-spacing: 0.35px;
            text-align: center;
            color: white;
            background-color: $color_lightpink;
        }
        .sort-by{
            display: flex;
        }
        .label{
            font-size: 16px;
            color: #646e78;
            margin-right: 17px;
        }
        .date-container{
            display: flex;
        }
    }
    ::v-deep{
        .inputs-container{
            .form-group{
                display: flex;
                align-items: center;
            }
            .form-group__label{
                margin-right: 18px;
                position: relative;
                top: 4px;

                @media screen and (max-width: $sm) {
                    width: 50px;
                }
            }
        }
        .select-element{
            display: flex;
            align-items: center;
            .select-element__label{
                margin-right: 18px;
                position: relative;
                top: 4px;

                @media screen and (max-width: $sm) {
                    width: 50px;
                }

            }
        }
    }
}

</style>
